import React, { useContext, useMemo, useState, useEffect } from "react";
import Select from "react-select";
import { Tooltip } from "react-tooltip";
import { useNavigate } from "react-router-dom";
import Badge from "react-bootstrap/Badge";
import Stack from "react-bootstrap/Stack";
import {
  useTable,
  useGlobalFilter,
  useSortBy,
  usePagination,
} from "react-table";

import { ContextSidebarToggler } from "../../../../../Context/SidebarToggler/SidebarToggler";
import {
  InvoiceIcon,
  fileIcon,
  reportsIcon,
  searchIcon,
} from "../../../../../utils/ImportingImages/ImportingImages";
import PageHeader from "../../../../../templates/PageHeader";
import AssignJobListTable from "./AssignJobListTable";
import Breadcrumbs from "../../../../../templates/Breadcrumbs";
import ReactTableSkeleton from "../../../../../templates/ReactTableSkeleton";
import { AssignJobModal } from "./AssignJobModal";
import { GenerateInvoiceModal } from "../GenerateInvoice/GenerateInvoiceModal";
import { EditAssignJobModal } from "./EditAssignJobModal";
import { formatDate } from "../../../../../utils/utilities/utilityFunctions";
import { ContextAPI } from "../../../../../Context/ApiContext/ApiContext";
import { DeleteAssignJobModal } from "./DeleteAssignJobModal";
import { format, parse, parseISO, isValid } from "date-fns";
import { AssignJobModalTL } from "./AssignJobModelTL";

const AssignJobsContent = ({ setIsUpdated, isLoading }) => {
  const { userDetails } = useContext(ContextAPI);
  const userRole = localStorage.getItem("userRole");
  const navigate = useNavigate();
  const { initialState } = useContext(ContextAPI);
  const { sidebarClose } = useContext(ContextSidebarToggler);
  const [assignedJobs, setAssignedJobs] = useState([]);
  const [filters, setFilters] = useState({
    status: null,
    assignedTo: null,
  });


  const statusOptions = [
    { label: "In Progress", value: "In Progress" },
    { label: "On Hold", value: "On Hold" },
    { label: "Completed", value: "Completed" },
  ];
  const assignedToOptions = [
    { label: "Individual", value: "Individual" },
    { label: "Team", value: "Team" },
  ];

  const breadCrumbs = [
    {
      pageName: "Home",
      pageURL: "/dashboard",
    },
    {
      pageName: "Jobs",
      pageURL: "/jobs",
    },
    {
      pageName: "Assign Job",
      pageURL: "/assign-job",
    },
  ];

  const detectAndParseDate = (dateStr) => {
    // Check if the date string is in 'yyyy-MM-dd' format
    if (/^\d{4}-\d{2}-\d{2}$/.test(dateStr)) {
      return parseISO(dateStr);
    }
    // Check if the date string is in 'dd-MM-yyyy' format
    else if (/^\d{2}-\d{2}-\d{4}$/.test(dateStr)) {
      return parse(dateStr, "dd-MM-yyyy", new Date());
    }
    // Invalid date format
    return null;
  };

  const formatDate = (dateStr) => {
    if (!dateStr) return "N.A";

    const dateObj = detectAndParseDate(dateStr);

    if (!dateObj || !isValid(dateObj)) return "N.A";

    const day = format(dateObj, "d"); // Day without leading zero
    const month = format(dateObj, "MMM"); // Month abbreviation
    const year = format(dateObj, "yyyy"); // Year

    const suffix = (day) => {
      if (day >= 11 && day <= 13) return "th";
      switch (day % 10) {
        case 1:
          return "st";
        case 2:
          return "nd";
        case 3:
          return "rd";
        default:
          return "th";
      }
    };

    return `${day}${suffix(day)} ${month} ${year}`;
  };
  const tableColumns = [
    {
      Header: "Sr no.",
      accessor: "sr no.",
      Cell: ({ row }) => row.index + 1,
    },
    {
      Header: "Job Name",
      accessor: "job_name",
      Cell: ({ value }) => {
        const [jobName, date] = value.split(" - ");
        return (
          <div>
            <p className="m-0">{jobName}</p>
            <p className="m-0">{date}</p>
          </div>
        );
      },
    },
  
    {
      Header: "Client Name",
      accessor: "client_name",
    },
    // {
    //   Header: "Assigned to",
    //   accessor: "assign_to",
    //   Cell: ({ row }) => {
    //     return row.original?.assign_to === "Team"
    //       ? row.original?.team_details[0]?.team_name
    //         ? `Team - ${row.original?.team_details[0]?.team_name}`
    //         : "---"
    //       : row.original?.member_name[0] ?? "---";
    //   },
    // },

    {
      Header: "Assigned to",
      accessor: "assign_to",
      Cell: ({ row }) => {
        const { assign_to, team_details, member_name } = row.original;
        if (assign_to === "Team") {
          return team_details[0]?.team_name
            ? `Team - ${team_details[0]?.team_name}`
            : "---";
        }
        return member_name[0] ?? "---";
      },
    },

 


    {
      Header: "Duration",
      accessor: "due_on",
      Cell: ({ row }) => {
        const startDate = formatDate(row.original.assigned_on);
        const endDate = formatDate(row.original.due_on);
        return (
          <div className="gap-1">
            <p className="m-0">From: {startDate}</p>
            <p className="m-0">To: {endDate}</p>
          </div>
        );
      },
    },
    {
      Header: "Description",
      accessor: "job_description",
    },
    {
      Header: "Is Minimum Bill",
      accessor: "is_minimum_billing",
      // Cell: ({ value }) => (value === "1" ? "Yes" : "No"),
      Cell: ({ value }) => {
        return (
          <div className="d-flex justify-content-center align-items-center">
            <Stack direction="horizontal">
              {value === "1" ? (
                <Badge bg="success">Yes</Badge>
              ) : (
                <Badge bg="danger">No</Badge>
              )}
            </Stack>
          </div>
        );
      },
    },

    {
      Header: "Status",
      accessor: "job_status",
      Cell: ({ row }) => {
        return (
          <div className="d-flex justify-content-center align-items-center">
            <Stack direction="horizontal">
              {row.original.job_status === "Completed" ? (
                <Badge bg="success">Completed</Badge>
              ) : row.original.job_status === "On Hold" ? (
                <Badge bg="danger">On Hold</Badge>
              ) : row.original.job_status === "Pending" ? (
                <Badge bg="danger">Pending</Badge>
              ) : row.original.job_status === "In Progress" ? (
                <Badge bg="warning" text="dark">
                  In Progress
                </Badge>
              ) : null}
            </Stack>
          </div>
        );
      },
    },

    {
      Header: "Approved Time",
      accessor: "approved_time",
      Cell: ({ row }) => {
        return (
          <div className="d-flex justify-content-center align-items-center">
            <Stack direction="horizontal">
              {row.original.approved_time === "yes" ? (
                <Badge bg="success">Yes</Badge>
              ) : (
                <Badge bg="danger">No</Badge>
              )}
            </Stack>
          </div>
        );
      },
    },

    ...(userRole !== "members,team_sub_leader" &&
    userRole !== "team_leaders,members"
      ? [
          {
            Header: "Edit",
            Cell: ({ row }) => (
              <div className="table-actions-wrapper d-flex justify-content-center align-items-center">
                {row.original.is_post_draft === "yes" &&
                row.original.is_post_draft_invoice_generated === "1" ? (
                  <>
                    <Tooltip
                      id="preview-post-draft-changes-invoice-tooltip"
                      style={{
                        background: "#000",
                        color: "#fff",
                      }}
                      opacity={0.9}
                    />
                    <div
                      data-tooltip-id="preview-post-draft-changes-invoice-tooltip"
                      data-tooltip-content="Preview Post Draft Changes Invoice"
                      data-tooltip-place="top"
                    >
                      <div
                        onClick={() => {
                          navigate("/invoice", {
                            state: {
                              invoiceMeta: {
                                post_draft_invoice_id:
                                  row.original?.post_draft_invoice_id,
                              },
                              assignId: row.original?.assign_id,
                              isInvoicePreview: true,
                            },
                          });
                        }}
                      >
                        <InvoiceIcon />
                      </div>
                    </div>
                  </>
                ) : row.original.is_post_draft === "yes" ? (
                  <>
                    <Tooltip
                      id="generate-post-draft-changes-invoice-tooltip"
                      style={{
                        background: "#000",
                        color: "#fff==",
                      }}
                      opacity={0.9}
                    />
                    <div
                      data-tooltip-id="generate-post-draft-changes-invoice-tooltip"
                      data-tooltip-content="Generate Post Draft Changes Invoice"
                      data-tooltip-place="top"
                    >
                      <GenerateInvoiceModal
                        jobData={row.original}
                        setIsUpdated={setIsUpdated}
                      />
                    </div>
                  </>
                ) : null}
                {
                  row.original.invoice_genrated === "Yes" &&
                  row.original.job_status === "Completed" ? (
                    <>
                      <Tooltip
                        id="preview-invoice-tooltip"
                        style={{
                          background: "#000",
                          color: "#fff",
                        }}
                        opacity={0.9}
                      />
                      <div
                        data-tooltip-id="preview-invoice-tooltip"
                        data-tooltip-content="Preview Invoice"
                        data-tooltip-place="top"
                      >
                        <div
                          onClick={() => {
                            const path =
                              row.original.is_minimum_billing === "1"
                                ? "/genrate/invoice/minimum-balance"
                                : "/invoice";
                            navigate(path, {
                              state: {
                                invoiceMeta: {
                                  invoice_id: row.original?.invoice_id,
                                },
                                assignId: row.original?.assign_id,
                                isInvoicePreview: true,
                              },
                            });
                          }}
                        >
                          <InvoiceIcon />
                        </div>
                      </div>
                    </>
                  ) : row.original.job_status === "Completed" ? (
                    <>
                      <Tooltip
                        id="generate-invoice-tooltip"
                        style={{
                          background: "#000",
                          color: "#fff", 
                        }}
                        opacity={0.9}
                      />
                      <div
                        data-tooltip-id="generate-invoice-tooltip"
                        data-tooltip-content="Generate Invoice"
                        data-tooltip-place="top"
                      >
                        <GenerateInvoiceModal
                          type={
                            row.original.is_minimum_billing === "1"
                              ? "minimum"
                              : "invoice"
                          }
                          jobData={row.original}
                          setIsUpdated={setIsUpdated}
                        />
                      </div>
                    </>
                  ) : null
                }

                <Tooltip
                  id="edit-assign-job-tooltip"
                  style={{
                    background: "#000",
                    color: "#fff",
                  }}
                  opacity={0.9}
                />
                <div
                  data-tooltip-id="edit-assign-job-tooltip"
                  data-tooltip-content="Edit Assigned Job"
                  data-tooltip-place="top"
                >
                  <EditAssignJobModal
                    assignJobData={row.original}
                    setIsUpdated={setIsUpdated}
                  />
                </div>
                {row.original.job_status !== "Completed" && (
                  <>
                <Tooltip
                  id="delete-assign-job-tooltip"
                  style={{
                    background: "#000",
                    color: "#fff",
                  }}
                  opacity={0.9}
                />
                <div
                  data-tooltip-id="delete-assign-job-tooltip"
                  data-tooltip-content="Delete Assigned Job"
                  data-tooltip-place="top"
                >
                  <DeleteAssignJobModal
                    assignJobData={row.original}
                    setIsUpdated={setIsUpdated}
                  />
                </div>
                </>)}
                
              </div>
            ),
          },
        ]
      : []),
  ];

  const columnHeaders = [
    "Sr no",
    "Job Name",
    "Assigned to",
    "Client Name",
    "Assigned on",
    "Due date",
    "Status",
    "Description",
    "Edit",
  ];

  const userId = userDetails?.member_id; // Get the member ID from user details

  let filteredJobs = assignedJobs; // Start with all assigned jobs

  // Check user roles before filtering
  if (
    userDetails?.member_role === "members,team_sub_leader" ||
    userDetails?.member_role === "members" ||
    userDetails?.member_role === "team_leaders,members"
  ) {
    filteredJobs = assignedJobs.filter((job) => {
    
      return job.assign_by === userId;
    });
  }

  const columns = useMemo(() => tableColumns, []);
  // const data = useMemo(() => assignedJobs, [assignedJobs]);
  const data = useMemo(() => filteredJobs, [assignedJobs]);

  useEffect(() => {
    const filterByStatus = initialState?.assignJobsList?.filter((job) => {
      return filters.status ? job.job_status === filters.status.value : job;
    });

    const filterByAssignTo = filterByStatus?.filter((job) => {
      return filters.assignedTo
        ? job.assign_to === filters.assignedTo.value
        : job;
    });

    setAssignedJobs(() => filterByAssignTo);
  }, [filters, initialState?.assignJobsList]);

  const tableInstance = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  // constructing headers for CSV Link
  const headers = {
    headings: [
      { label: "Job Name", key: "job_name" },
      { label: "Client Name", key: "client_name" },
      { label: "Job assigned to", key: "assign_to" },
      { label: "Job assigned on", key: "assigned_on" },
      { label: "Job due date", key: "due_on" },
      { label: "Job Status", key: "job_status" },
      { label: "Approved Time", key: "approved_time" },
      { label: "Job Description", key: "job_description" },
    ],
    fileName: "Assigned Jobs",
  };
  // Function to transform data for CSV export
  const transformDataForCSV = (AssignedJobs) => {
    return AssignedJobs.map((job) => {
      return {
        job_name: job?.job_name,
        client_name: job?.client_name,
        assign_to: job?.assign_to,
        assigned_on: job?.assigned_on,
        due_on: job?.due_on,
        job_status: job?.job_status,
        approved_time: job?.approved_time ? job?.approved_time : "No",
        job_description: job?.job_description,
      };
    });
  };

  // Use the function to get CSV data
  const csvInvoiceData = transformDataForCSV(data);
  return (
    <div className={`main-content ${sidebarClose ? "sidebarClose" : ""}`}>
      <div className="mr-40 ml-30 mb-15">
        <Breadcrumbs crumbs={breadCrumbs} />
      </div>


      <PageHeader
        tableInstance={tableInstance}
        icon={fileIcon}
        headerTitle={"Assign Job"}
      >
        {userDetails?.member_role === "members,team_sub_leader" ||
        userDetails?.member_role === "team_leaders,members" ? (
          <AssignJobModalTL setIsUpdated={setIsUpdated} />
        ) : (
          <AssignJobModal setIsUpdated={setIsUpdated} />
        )}
      </PageHeader>


      <div className="mr-40 ml-30 mt-5 mb-15 w-75 d-flex justify-content-start align-items-center gap-4">
        <div className="relative-wrapper w-25">
          <img className="search-icon" src={searchIcon} alt="search-icon" />
          <Select
            closeMenuOnSelect={true}
            isClearable={true}
            options={assignedToOptions}
            onChange={(option) => {
              setFilters((prev) => ({
                ...prev,
                assignedTo: option,
              }));
            }}
            value={filters.assignedTo}
            placeholder="Select assigned to"
            className="react-select-custom-styling__container"
            classNamePrefix="react-select-custom-styling"
          />
        </div>

        <div className="relative-wrapper w-25">
          <img className="search-icon" src={reportsIcon} alt="search-icon" />
          <Select
            closeMenuOnSelect={true}
            isClearable={true}
            options={statusOptions}
            onChange={(option) => {
              setFilters((prev) => ({
                ...prev,
                status: option,
              }));
            }}
            value={filters.status}
            placeholder="Select status"
            className="react-select-custom-styling__container"
            classNamePrefix="react-select-custom-styling"
          />
        </div>
      </div>


      {isLoading ? (
        <ReactTableSkeleton columnHeaders={columnHeaders} />
      ) : filteredJobs?.length > 0 ? (
        <AssignJobListTable
          tableInstance={tableInstance}
          headers={headers}
          assignedJobs={csvInvoiceData}

          // setIsUpdated={setIsUpdated}
        />
      ) : (
        <div className="mt-4 mr-40 ml-30 mb-15">
          <h5>No data found!</h5>
        </div>
      )}
    </div>
  );
};

export default AssignJobsContent;