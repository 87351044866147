import React, { useContext, useEffect, useMemo, useState } from "react";
import {
  useTable,
  useGlobalFilter,
  useSortBy,
  usePagination,
} from "react-table";
import Select from "react-select";
import { Tooltip } from "react-tooltip";
import Breadcrumbs from "../../../../templates/Breadcrumbs";
import { ContextSidebarToggler } from "../../../../Context/SidebarToggler/SidebarToggler";
import PageHeader from "../../../../templates/PageHeader";
import ReportsTable from "../../components/ReportsTable";
// import ClientWiseTable from "./ClientWiseTable";
import ReactTableSkeleton from "../../../../templates/ReactTableSkeleton";
import {
  InvoiceIcon,
  reportsIcon,
  userIcon1,
} from "../../../../utils/ImportingImages/ImportingImages";
import AdvanceBillingReportTable from "./AdvanceBillingReportTable";
import { ContextAPI } from "../../../../Context/ApiContext/ApiContext";
import { AdvanceBillingReportModal } from "./AdvanceBillingReportModel";
import Badge from "react-bootstrap/Badge";
import Stack from "react-bootstrap/Stack";
import { useNavigate } from "react-router-dom";
// import { ClientWiseReportModal } from "./ClientWiseReportModel";

// Bread Crumbs
const breadCrumbs = [
  {
    pageName: "Home",
    pageURL: "/dashboard",
  },

  {
    pageName: "Advance Billing Report",
    pageURL: "/reports/advance_billing_report",
  },
];

// constructing Headers for React Skelton
const columnHeaders = ["Sr no.", "Client Code", "Name", "Email ID", "Edit"];

// constructing headers for CSV Link
const headers = {
  headings: [
    { label: "Client Code", key: "client_code" },
    { label: "Email ID", key: "email" },
    { label: "Name", key: "name" },
  ],
  fileName: "Clients List",
};

const AdvanceBillingReportContent = ({
  advancebillingReportData,
  setIsUpdated,
  isLoading,
}) => {
  const { getAllAdvanceBillingJobs, mainURL } = useContext(ContextAPI);
  const navigate = useNavigate();
  const { sidebarClose } = useContext(ContextSidebarToggler);
  const [reportData, setReportData] = useState([]);
  const [filters, setFilters] = useState({
    status: null,
    assignedTo: null,
  });

  const statusOptions = [
    { label: "In Progress", value: "In Progress" },
    { label: "On Hold", value: "On Hold" },
    { label: "Completed", value: "Completed" },
  ];

  useEffect(() => {
    const filterByStatus = advancebillingReportData?.filter((report) => {
      return filters.status ? report.job_status === filters.status.value : true;
    });
    setReportData(filterByStatus || []);
  }, [filters, advancebillingReportData]);

  const tableColumns = [
    {
      Header: "Sr no.",
      accessor: "sr no.",
      Cell: ({ row }) => row.index + 1,
    },
    {
      Header: "Job Name",
      accessor: "job_name",
    },
    {
      Header: "Client Name",
      accessor: "client_name",
    },
    {
      Header: "Assigned On",
      accessor: "assigned_on",
    },
    {
      Header: "Status",
      accessor: "job_status",
      Cell: ({ row }) => (
        <div className="d-flex justify-content-center align-items-center">
          <Stack direction="horizontal">
            {row.original.job_status === "Completed" ? (
              <Badge bg="success">Completed</Badge>
            ) : row.original.job_status === "On Hold" ? (
              <Badge bg="danger">On Hold</Badge>
            ) : (
              <Badge bg="warning" text="dark">
                In Progress
              </Badge>
            )}
          </Stack>
        </div>
      ),
    },
    {
      Header: "Email ID",
      accessor: "email",
      Cell: ({ row }) =>
        JSON.parse(row.original?.additional_data)?.primary_email,
    },
    {
      Header: "Allocated Hours",
      accessor: "allocated_hours",
      Cell: ({ row }) => {
        const time = row.original.allocated_hours;
        return time.slice(0, -3);
      },
    },
    {
      Header: "Action",
      Cell: ({ row }) => (
        <div className="table-actions-wrapper d-flex justify-content-end align-items-center">
          <Tooltip
            id="delete-client-tooltip"
            style={{
              background: "#000",
              color: "#fff",
            }}
            opacity={0.9}
          />
          <div
            style={{
              marginRight: "19%",
              cursor: "pointer",
              display: "flex",
              gap: "5px",
            }}
            data-tooltip-id="delete-client-tooltip"
            data-tooltip-content="Show more"
            data-tooltip-place="top"
          >
            <Tooltip
              id="preview-invoice-tooltip"
              style={{
                background: "#000",
                color: "#fff",
              }}
              opacity={0.9}
            />
            {row.original.invoice_url ? (
              <div
                data-tooltip-id="preview-invoice-tooltip"
                data-tooltip-content="Preview Invoice"
                data-tooltip-place="top"
              >
                <div
                  onClick={() => {
                    const invoiceUrl = `${mainURL}/${row.original.invoice_url}`;
                    const rowData = row.original;
                    const assignId = row.original.id;
                    const clientEmail = JSON.parse(
                      row.original?.additional_data
                    )?.primary_email;
                    navigate("/advance_billing_report_invoice", {
                      state: {
                        invoice_id: row.original.id,
                        invoice_url: invoiceUrl,
                        row: rowData,
                        assignId: assignId || null,
                        clientEmail: clientEmail,
                      },
                    });
                  }}
                >
                  <InvoiceIcon />
                </div>
              </div>
            ) : null}

            <Tooltip
              id="send-invoice-tooltip"
              style={{
                background: "#000",
                color: "#fff",
              }}
              opacity={0.9}
            />
            <AdvanceBillingReportModal
              AdvanceBillingReportData={row.original}
              setIsUpdated={setIsUpdated}
            />
          </div>
        </div>
      ),
    },
  ];

  const columns = useMemo(() => tableColumns, []);

  const data = useMemo(() => reportData, [reportData]);

  const tableInstance = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  return (
    <div className={`main-content ${sidebarClose ? "sidebarClose" : ""}`}>
      <div className="mr-40 ml-30 mb-15">
        <Breadcrumbs crumbs={breadCrumbs} />
      </div>

      {/* Top header section */}
      <div className="mb-5 relative-wrapper zIndex-2">
        <PageHeader
          tableInstance={tableInstance}
          icon={userIcon1}
          // icon={reportsIcon}
          headerTitle={"Advance Billing Report"}
        ></PageHeader>
      </div>
      <div className="ml-30 mr-40 relative-wrapper w-75">
        <img className="search-icon" src={reportsIcon} alt="search-icon" />
        <Select
          closeMenuOnSelect={true}
          isClearable={true}
          options={statusOptions}
          onChange={(option) => {
            setFilters((prev) => ({
              ...prev,
              status: option,
            }));
          }}
          value={filters.status}
          placeholder="Select status"
          className="react-select-custom-styling__container w-25"
          classNamePrefix="react-select-custom-styling"
        />
      </div>

      {/* Report Table  */}
      {isLoading ? (
        <ReactTableSkeleton columnHeaders={columnHeaders} />
      ) : reportData.length > 0 ? (
        <AdvanceBillingReportTable
          tableInstance={tableInstance}
          headers={headers}
          advancebillingReportData={reportData}
        />
      ) : (
        <div className="mr-40 ml-30 mb-15 mt-3">
          <h5>No Advance Billing Report Data Found !</h5>
        </div>
      )}
    </div>
  );
};

export default AdvanceBillingReportContent;
