// import React, { useContext, useEffect, useState } from "react";

// import MembersContent from "./components/MembersContent";
// import { ContextAPI } from "../../Context/ApiContext/ApiContext";

// export const Members = () => {
//   const { getAllMembers, initialState } = useContext(ContextAPI);
//   const [isUpdated, setIsUpdated] = useState(false);

//   // fetching all members api
//   useEffect(() => {
//     getAllMembers();
//   }, [isUpdated]);

//   return (
//     <MembersContent
//       membersData={initialState.membersList}
//       setIsUpdated={setIsUpdated}
//       isLoading={initialState.isLoading}
//     />
//   );
// };
import React, { useContext, useEffect, useState } from "react";

import MembersContent from "./components/MembersContent";
import { ContextAPI } from "../../Context/ApiContext/ApiContext";

export const Members = () => {
  const { getAllMembers, initialState } = useContext(ContextAPI);
  const [isUpdated, setIsUpdated] = useState(false);

  // Retrieve page number from localStorage, default to 1 if not found
  const storedPage = localStorage.getItem("currentPage");
  const [currentPage, setCurrentPage] = useState(
    storedPage ? Number(storedPage) : 1
  );

  const storedPageSize = localStorage.getItem("pageSize");
  const [pageSize, setPageSize] = useState(
    storedPageSize ? Number(storedPageSize) : 10
  );

  useEffect(() => {
    const handleRefresh = (event) => {
      setCurrentPage(1);
      localStorage.setItem("currentPage", 1);
      localStorage.setItem("pageSize", 10);
    };

    window.addEventListener("beforeunload", handleRefresh);

    return () => {
      window.removeEventListener("beforeunload", handleRefresh);
    };
  }, []);

  useEffect(() => {
    getAllMembers(currentPage, pageSize);
  }, [isUpdated, currentPage, pageSize]);

  return (
    <MembersContent
      membersData={initialState.membersList}
      totalPages={initialState?.totalPages}
      setIsUpdated={setIsUpdated}
      isLoading={initialState.isLoading}
      currentPage={currentPage}
      setCurrentPage={setCurrentPage}
      pageSize={pageSize}
      setPageSize={setPageSize}
    />
  );
};