
import React, { useState, useContext, useEffect } from "react";
import axios from "axios";
import Modal from "react-bootstrap/Modal";
import { SpinningLoader } from "../../../../../Components/SpinningLoader/SpinningLoader";
import { ContextAPI } from "../../../../../Context/ApiContext/ApiContext";
import { handleAPIError ,headerOptions} from "../../../../../utils/utilities/utilityFunctions";
import { ReactHotToast } from "../../../../../Components/ReactHotToast/ReactHotToast";

const MyVerticallyCenteredModal = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const { mainURL, logout } = useContext(ContextAPI);
  const userId = localStorage.getItem("userId") ?? null;

  const deleteJob = async () => {
    try {
      setIsLoading(true);
      const entryId = props.entryDetails?.entries_id;
      const url = `${mainURL}delete/time-entries/${userId}/${entryId}`;

      const result = await axios.delete(url, {
        headers: headerOptions(),
      });
      if (result.status === 200) {
        ReactHotToast(result.data.message, "success");
        props.setIsUpdated((prev) => !prev);
        props.onHide();
      }
    } catch (e) {
      handleAPIError(e, logout);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDelete = (e) => {
    e.preventDefault();
    deleteJob();
  };

  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header className="pt-3 pb-1" closeButton>
      <Modal.Title className="w-100" id="contained-modal-title-vcenter">
          <div className="d-flex justify-content-center align-items-center gap-3">
            {/* <img src={projectsIcon} height={20} width={20} alt="user-icon" /> */}
            {/* <TrashSVG/> */}
            <span className="modal-title">Delete Time Entry</span>
          </div>
        </Modal.Title>
      </Modal.Header> 
      <Modal.Body className="modal-body">
        <form
          onSubmit={handleDelete}
          className="d-flex flex-column justify-content-center align-items-center"
        >
          <div className="form-group mt-2 w-100">
            <p className="text-center fs-5 w-100 m-auto">
              Are you sure you want to delete "{props?.entryDetails?.task_name}"?
            </p>
          </div>

          <button type="submit" className="custom-btn mt-4">
            {isLoading ? <SpinningLoader /> : "Delete"}
          </button>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export const DeleteTimeEntryModal = ({ entryDetails, setIsUpdated }) => {
  const [modalShow, setModalShow] = useState(false);

  return (
    <>
      <div onClick={() => setModalShow(true)}>
        {entryDetails?.time_entries_status === "pending" && (
          <button className="custom-btn4 d-flex justify-content-center align-items-center gap-2 float-right">
            Delete
          </button>
        )}
      </div>
      <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        setIsUpdated={setIsUpdated}
        entryDetails={entryDetails}
      />
    </>
  );
};
