import React, { useContext, useMemo, useEffect } from "react";
import { Tooltip } from "react-tooltip";

import {
  useTable,
  useGlobalFilter,
  useSortBy,
  usePagination,
} from "react-table";
import { json, useNavigate } from "react-router-dom";
import {
  advanceBilling,
  advanceBillingg,
  InvoiceIcon,
  fileIcon,
} from "../../../utils/ImportingImages/ImportingImages";
import PageHeader from "../../../templates/PageHeader";
import { ContextSidebarToggler } from "../../../Context/SidebarToggler/SidebarToggler";
import Breadcrumbs from "../../../templates/Breadcrumbs";
import AdvanceBillingTable from "./AdvanceBillingTable";
import { ContextAPI } from "../../../Context/ApiContext/ApiContext";
import ReactTableSkeleton from "../../../templates/ReactTableSkeleton";
import { AdvanceBillingJobModal } from "./AdvanceBillingJobModel";
import Badge from "react-bootstrap/Badge";
import Stack from "react-bootstrap/Stack";
import { format, parse, parseISO, isValid } from "date-fns";
import { DeleteAdvanceBillingJobModal } from "./DeleteAdvanceBillingJobModel";
import { UpdateAdvanceBillingJobModal } from "./UpdateAdvanceBillingJobModel";

const AdvancBillingContent = ({
  advancBillingJobsData,
  setIsUpdated,
  isLoading,
}) => {
  const navigate = useNavigate();
  const { sidebarClose } = useContext(ContextSidebarToggler);
  const { getAllAdvanceBillingJobs, mainURL } = useContext(ContextAPI);
  useEffect(() => {
    getAllAdvanceBillingJobs();
  }, []);
  const detectAndParseDate = (dateStr) => {
    // Check if the date string is in 'yyyy-MM-dd' format
    if (/^\d{4}-\d{2}-\d{2}$/.test(dateStr)) {
      return parseISO(dateStr);
    }
    // Check if the date string is in 'dd-MM-yyyy' format
    else if (/^\d{2}-\d{2}-\d{4}$/.test(dateStr)) {
      return parse(dateStr, "dd-MM-yyyy", new Date());
    }
    // Invalid date format
    return null;
  };
  const formatDate = (dateStr) => {
    if (!dateStr) return "N.A";

    const dateObj = detectAndParseDate(dateStr);

    if (!dateObj || !isValid(dateObj)) return "N.A";

    const day = format(dateObj, "d"); // Day without leading zero
    const month = format(dateObj, "MMM"); // Month abbreviation
    const year = format(dateObj, "yyyy"); // Year

    const suffix = (day) => {
      if (day >= 11 && day <= 13) return "th";
      switch (day % 10) {
        case 1:
          return "st";
        case 2:
          return "nd";
        case 3:
          return "rd";
        default:
          return "th";
      }
    };

    return `${day}${suffix(day)} ${month} ${year}`;
  };

  const breadCrumbs = [
    {
      pageName: "Home",
      pageURL: "/dashboard",
    },
    {
      pageName: "Adv Billing Jobs",
      // pageURL: "/advance-billing-jobs",
    },
  ];

  const tableColumns = [
    {
      Header: "Sr no.",
      accessor: "sr no.",
      Cell: ({ row }) => row.index + 1,
    },
    // {
    //   Header: "BPO No",
    //   accessor: "bpo_no",
    // },
    {
      Header: "Job Name",
      accessor: "job_name",
    },
    {
      Header: "Client Name",
      accessor: "client_name",
    },

    {
      Header: "Assigned on",
      accessor: "assigned_on",
      Cell: ({ row }) => {
        const startDate = formatDate(row.original.assigned_on);
        return (
          <div className="gap-1">
            <p className="m-0">{startDate}</p>
          </div>
        );
      },
    },
    {
      Header: "Allocated Hours",
      accessor: "allocated_hours",
      Cell: ({ row }) => {
        const time = row.original.allocated_hours;
        return time.slice(0, -3);
      },
    },

    {
      Header: "Approved Time",
      accessor: "approved_time",
      Cell: ({ row }) => {
        return (
          <div className="d-flex justify-content-center align-items-center">
            <Stack direction="horizontal">
              {row.original.approved_time === "yes" ? (
                <Badge bg="success">Yes</Badge>
              ) : (
                <Badge bg="danger">No</Badge>
              )}
            </Stack>
          </div>
        );
      },
    },

    {
      Header: "Status",
      accessor: "job_status",
      Cell: ({ row }) => (
        <div className="d-flex justify-content-center align-items-center">
          <Stack direction="horizontal">
            {row.original.job_status === "Completed" ? (
              <Badge bg="success">Completed</Badge>
            ) : row.original.job_status === "On Hold" ? (
              <Badge bg="danger">On Hold</Badge>
            ) : (
              <Badge bg="warning" text="dark">
                In Progress
              </Badge>
            )}
          </Stack>
        </div>
      ),
    },

    {
      Header: "Edit",
      Cell: ({ row }) => (
        <div className="table-actions-wrapper d-flex justify-content-center align-items-center">
          <>
            <Tooltip
              id="preview-invoice-tooltip"
              style={{
                background: "#000",
                color: "#fff",
              }}
              opacity={0.9}
            />
            {row.original.invoice_url ? (
              <div
                data-tooltip-id="preview-invoice-tooltip"
                data-tooltip-content="Preview Invoice"
                data-tooltip-place="top"
              >
                <div
                  onClick={() => {
                    const invoiceUrl = `${mainURL}/${row.original.invoice_url}`;
                    const rowData = row.original;
                    const assignId = row.original.assign_id;
                    const clientEmail = row.original.client_email;
                    navigate("/advance-billing-invoice", {
                      state: {
                        invoice_id: row.original.id,
                        invoice_url: invoiceUrl,
                        row: rowData,
                        assignId: assignId || null,
                        clientEmail: clientEmail,
                      },
                    });
                  }}
                >
                  <InvoiceIcon />
                </div>
              </div>
            ) : null}

            <Tooltip
              id="send-invoice-tooltip"
              style={{
                background: "#000",
                color: "#fff",
              }}
              opacity={0.9}
            />

            <Tooltip
              id="edit-advance-job-tooltip"
              style={{
                background: "#000",
                color: "#fff",
              }}
              opacity={0.9}
            />
            <div
              data-tooltip-id="edit-advance-job-tooltip"
              data-tooltip-content="Edit Advance Job"
              data-tooltip-place="top"
            >
              <UpdateAdvanceBillingJobModal
                id={row.original.assign_id}
                status={row.original.job_status}
                teamId={row.original.team_id}
                jobName={row.original.job_name}
                setIsUpdated={setIsUpdated}
              />
            </div>

            <Tooltip
              id="delete-advance-job-tooltip"
              style={{
                background: "#000",
                color: "#fff",
              }}
              opacity={0.9}
            />
            <div
              data-tooltip-id="delete-advance-job-tooltip"
              data-tooltip-content="Delete Advance Job"
              data-tooltip-place="top"
            >
              <DeleteAdvanceBillingJobModal
                id={row.original.assign_id}
                setIsUpdated={setIsUpdated}
              />
            </div>
          </>
        </div>
      ),
    },
  ];

  const columnHeaders = [
    "Sr no.",
    "Assigned On",
    "Job Name",
    "Client Name",
    "Status",
    "Edit",
  ];

  const columns = useMemo(() => tableColumns, []);
  // const data = useMemo(() => advancBillingJobsData, [advancBillingJobsData]);
  const data = useMemo(() => {
    return advancBillingJobsData?.sort((a, b) => {
      if (a.job_status === "In Progress" && b.job_status !== "In Progress") {
        return -1;
      }
      if (a.job_status !== "In Progress" && b.job_status === "In Progress") {
        return 1;
      }
      return 0;
    });
  }, [advancBillingJobsData]);

  const tableInstance = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  // constructing headers for CSV Link
  const headers = {
    headings: [
      { label: "Job Name", key: "job_name" },
      { label: "Client Name", key: "client_name" },
      { label: "Assigned On", key: "assigned_on" },
      { label: "Status", key: "job_status" },
      { label: "Allocated Hours", key: "allocated_hours" },
    ],
    fileName: "Advance Billing Jobs",
  };

  return (
    <div className={`main-content ${sidebarClose ? "sidebarClose" : ""}`}>
      <div className="mr-40 ml-30 mb-15">
        <Breadcrumbs crumbs={breadCrumbs} />
      </div>

      {/* Top header section */}
      <div className="relative-wrapper zIndex-2">
        <PageHeader
          tableInstance={tableInstance}
          icon={advanceBillingg}
          headerTitle={"Adv Billing Jobs"}
        >
          <AdvanceBillingJobModal setIsUpdated={setIsUpdated} />
        </PageHeader>
      </div>

      {/* Advance billing Jobs Table */}
      <div className="mt-5">
        {isLoading ? (
          <ReactTableSkeleton columnHeaders={columnHeaders} />
        ) : advancBillingJobsData?.length > 0 ? (
          <AdvanceBillingTable
            tableInstance={tableInstance}
            headers={headers}
            advancBillingJobsData={advancBillingJobsData}
          />
        ) : (
          <div className="mr-40 ml-30 mb-15">
            <h5>No data found!</h5>
          </div>
        )}
      </div>
    </div>
  );
};

export default AdvancBillingContent;
