import React, { useContext, useMemo ,useState} from "react";
import { useNavigate } from "react-router-dom";
import { Tooltip } from "react-tooltip";

import PageHeader from "../../../templates/PageHeader";
import { ContextSidebarToggler } from "../../../Context/SidebarToggler/SidebarToggler";
import ClientsTable from "./ClientsTable";
import Breadcrumbs from "../../../templates/Breadcrumbs";
import { DeleteClientModal } from "./DeleteClientModal";
import ReactTableSkeleton from "../../../templates/ReactTableSkeleton";

import { EditSVG } from "../../../utils/ImportingImages/ImportingImages";

import {
  useTable,
  useGlobalFilter,
  useSortBy,
  usePagination,
} from "react-table";

import { clientsIcon1 ,searchIcon} from "../../../utils/ImportingImages/ImportingImages";

import "../../../stylesheet/CommonCSS.css";
import { ClientAdditionalDataModel } from "./ClientAdditionalDataModal";

const breadCrumbs = [
  {
    pageName: "Home",
    pageURL: "/dashboard",
  },
  {
    pageName: "Clients",
    pageURL: "/clients",
  },
];
const columnHeaders = ["Sr no.", "Client Code", "Name", "Email ID", "Edit"];
// constructing headers for CSV Link
const headers = {
  headings: [
    { label: "Client Code", key: "client_code" },
    { label: "Client BPO No", key: "bpo_no" },
    { label: "Client Name", key: "client_name" },
    { label: "Company Name", key: "company_name" },
    { label: "Email ID", key: "client_email" },
  ],
  fileName: "Clients List",
};

const ClientsContent = ({ clientsData, setIsUpdated, isLoading }) => {
  const navigate = useNavigate();
  const { sidebarClose } = useContext(ContextSidebarToggler);
  const [searchInput, setSearchInput] = useState("");

  const tableColumns = [
    {
      Header: "Sr no.",
      accessor: "sr no.",
      Cell: ({ row }) => row.index + 1,
    },
    {
      Header: "Client BPO No",
      // accessor: "client_code",
      Cell: ({ row }) => row.original?.additional_data?.bpo_no,
    },
    {
      Header: "Name",
      accessor: "client_name",
    },
    {
      Header: "Company Name",
      accessor: "company_name",
      Cell: ({ row }) => row.original?.additional_data?.company_name,
    },
    {
      Header: "Email ID",
      accessor: "client_email",
      Cell: ({ row }) => row.original?.additional_data?.primary_email,
    },
    {
      Header: "Edit",
      Cell: ({ row }) => (
        <div className="table-actions-wrapper d-flex justify-content-end align-items-center">
          <Tooltip
            id="delete-client-tooltip"
            style={{
              background: "#000",
              color: "#fff",
            }}
            opacity={0.9}
          />
          <div
            data-tooltip-id="delete-client-tooltip"
            data-tooltip-content="Client Additional Data"
            data-tooltip-place="top"
            style={{ cursor: "pointer" }}
          >
            <ClientAdditionalDataModel
              clientData={row.original}
              setIsUpdated={setIsUpdated}
            />
          </div>

          <Tooltip
            id="edit-client-tooltip"
            style={{
              background: "#000",
              color: "#fff",
            }}
            opacity={0.9}
          />
          <div
            data-tooltip-id="edit-client-tooltip"
            data-tooltip-content="Edit Client Details"
            data-tooltip-place="top"
            onClick={() => navigate(`/clients/${row.original.client_id}`)}
          >
            <EditSVG />
          </div>

          <Tooltip
            id="delete-client-tooltip"
            style={{
              background: "#000",
              color: "#fff",
            }}
            opacity={0.9}
          />
          <div
            data-tooltip-id="delete-client-tooltip"
            data-tooltip-content="Delete Client"
            data-tooltip-place="top"
          >
            <DeleteClientModal
              clientData={row.original}
              setIsUpdated={setIsUpdated}
            />
          </div>
        </div>
      ),
    },
  ];

  const columns = useMemo(() => tableColumns, []);
  
  // const data = useMemo(() => clientsData, [clientsData]);
  const data = useMemo(() => {
    const filteredData = clientsData.filter((row) => {
      let additionalData = row.additional_data;
  
 
      if (typeof additionalData === "string") {
        try {
          additionalData = JSON.parse(additionalData);
        } catch (error) {
          console.error("Error parsing additional_data:", error);
          additionalData = {}; 
        }
      }
      const bpoNo = additionalData.bpo_no?.toString().toLowerCase() || ""; 
      const clientName = row.client_name?.toLowerCase() || ""; 
      const companyName=additionalData.company_name?.toString().toLowerCase() || ""; 
      const searchTerm = searchInput.toLowerCase();

      return (
        clientName.includes(searchTerm) ||
        bpoNo.includes(searchTerm)||companyName.includes(searchTerm)
      );
    });

    return filteredData; 
  }, [clientsData, searchInput]);

  const tableInstance = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );
  // Function to transform data for CSV export
  const transformDataForCSV = (clientsData) => {
    return clientsData.map((client) => {
      return {
        client_code: client.client_code,
        bpo_no: client?.additional_data?.bpo_no
          ? client?.additional_data?.bpo_no
          : "--",
        client_name: client?.client_name,
        company_name: client?.additional_data?.company_name,
        client_email: client?.client_email,
      };
    });
  };

  // Use the function to get CSV data
  const csvInvoiceData = transformDataForCSV(data);
  return (
    <div className={`main-content ${sidebarClose ? "sidebarClose" : ""}`}>
      <div className="mr-40 ml-30 mb-15">
        <Breadcrumbs crumbs={breadCrumbs} />
      </div>

      {/* Top header section */}
      <section className="main-content_header add-border-bottom ">
        <div className="d-flex justify-content-center align-items-center page-heading">
          <img src={clientsIcon1} alt="members" />
          <p className="m-0 fs-4">Clients</p>
        </div>
        <div className="d-flex justify-content-center align-items-center gap-3">
          <div className="relative-wrapper">
            <img className="search-icon" src={searchIcon} alt="search-icon" />
            <input
              className="input-field"
              type="text"
              placeholder="Search"
              value={searchInput}
              onChange={(e) => setSearchInput(e.target.value)}
            />
          </div>
          <div>
          {" "}
          <button
            onClick={() => navigate("/clients/add-client")}
            className="custom-btn d-flex justify-content-center align-items-center gap-2"
          >
            Add Client <span className="fw-light fs-4">+</span>
          </button>
        </div>
        </div>
      </section>

      {/* <div className="mb-5 relative-wrapper zIndex-2">
        <PageHeader
          tableInstance={tableInstance}
          icon={clientsIcon1}
          headerTitle={"Clients"}
        >
          <button
            onClick={() => navigate("/clients/add-client")}
            className="custom-btn d-flex justify-content-center align-items-center gap-2"
          >
            Add Client <span className="fw-light fs-4">+</span>
          </button>
        </PageHeader>
      </div> */}

      {/* Clients Table */}
      {isLoading ? (
        <ReactTableSkeleton columnHeaders={columnHeaders} />
      ) : clientsData.length > 0 ? (
        <ClientsTable
          tableInstance={tableInstance}
          headers={headers}
          // clientsData={clientsData}
          clientsData={csvInvoiceData}
        />
      ) : (
        <div className="mr-40 ml-30 mb-15 mt-5">
          <h5>No Clients Found, Please add new Client!</h5>
        </div>
      )}
    </div>
  );
};

export default ClientsContent;
