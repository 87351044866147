import React, { useContext, useState, useMemo, useEffect } from "react";
import { Tooltip } from "react-tooltip";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import {
  useTable,
  useGlobalFilter,
  useSortBy,
  usePagination,
} from "react-table";
import Select from "react-select";
import { ContextSidebarToggler } from "../../../Context/SidebarToggler/SidebarToggler";
import Breadcrumbs from "../../../templates/Breadcrumbs";
import {
  clientsIcon1,
  adHoc,
  searchIcon,
  PlusIconSVG,
  TrashSVG,
  InvoiceIcon,
  EditSVG,
} from "../../../utils/ImportingImages/ImportingImages";
import { SpinningLoader } from "../../../Components/SpinningLoader/SpinningLoader";
import AdHocTable from "./AdHocTable";
import { ContextAPI } from "../../../Context/ApiContext/ApiContext";
import { ReactHotToast } from "../../../Components/ReactHotToast/ReactHotToast";
import {
  handleAPIError,
  headerOptions,
} from "../../../utils/utilities/utilityFunctions";
import ReactTableSkeleton from "../../../templates/ReactTableSkeleton";
// import { EditBillingServicesModal } from "./EditBillingServicesModal";
import { format, parse, parseISO, isValid } from "date-fns";
const AdHocContent = ({ adHocClientsList, isLoading, setIsUpdated, adhoc }) => {
  const { sidebarClose } = useContext(ContextSidebarToggler);
  const { mainURL, logout, initialState, getAllClients, getAllAdHocClients } =
    useContext(ContextAPI);

  const navigate = useNavigate();
  const [isUserValid, setIsUserValid] = useState(false);
  const [client, setClient] = useState("");
  useEffect(() => {
    getAllClients();
    getAllAdHocClients();
  }, []);

  const detectAndParseDate = (dateStr) => {
    // Check if the date string is in 'yyyy-MM-dd' format
    if (/^\d{4}-\d{2}-\d{2}$/.test(dateStr)) {
      return parseISO(dateStr);
    }
    // Check if the date string is in 'dd-MM-yyyy' format
    else if (/^\d{2}-\d{2}-\d{4}$/.test(dateStr)) {
      return parse(dateStr, "dd-MM-yyyy", new Date());
    }
    // Invalid date format
    return null;
  };
  const formatDate = (dateStr) => {
    if (!dateStr) return "N.A";

    const dateObj = detectAndParseDate(dateStr);

    if (!dateObj || !isValid(dateObj)) return "N.A";

    const day = format(dateObj, "d"); // Day without leading zero
    const month = format(dateObj, "MMM"); // Month abbreviation
    const year = format(dateObj, "yyyy"); // Year

    const suffix = (day) => {
      if (day >= 11 && day <= 13) return "th";
      switch (day % 10) {
        case 1:
          return "st";
        case 2:
          return "nd";
        case 3:
          return "rd";
        default:
          return "th";
      }
    };

    return `${day}${suffix(day)} ${month} ${year}`;
  };
  const breadCrumbs = [
    {
      pageName: "Home",
      pageURL: "/dashboard",
    },
    {
      pageName: "Ad Hoc",
      pageURL: "",
    },
  ];
  const [options, setOptions] = useState({
    clientOptions: [],
  });

  const tableColumns = [
    {
      Header: "Sr no.",
      accessor: "sr_id",
      Cell: ({ row }) => row.index + 1,
    },
    {
      Header: "Client Name",
      accessor: "client_name",
    },
    {
      Header: "BPO No",
      accessor: "bpo_no",
      filter: "custom",
      Cell: ({ row }) => {
        const additionalData = JSON.parse(row.original.additional_data);
        return additionalData.bpo_no || "N/A";
      },
    },
    {
      Header: "Created Date",
      accessor: "created_on",
      Cell: ({ row }) => {
        const startDate = formatDate(row.original.created_on);
        return (
          <div className="gap-1">
            <p className="m-0">{startDate}</p>
          </div>
        );
      },
    },

    {
      Header: "invoice No",
      accessor: "invoice_no",
    },

    {
      Header: "Action",
      Cell: ({ row }) => (
        <div className="table-actions-wrapper d-flex justify-content-center align-items-center">
          <Tooltip
            id="generate-invoice-tooltip"
            style={{
              background: "#000",
              color: "#fff",
            }}
            opacity={0.9}
          />
          {row.original.invoice_url ? null : (
            <div
              data-tooltip-id="generate-invoice-tooltip"
              data-tooltip-content="Generate Invoice"
              data-tooltip-place="top"
              onClick={() => {
                const invoiceNo = row.original.invoice_no;
                const adHocMailInvoice = "adHocMailInvoice";
                navigate(`/hoc-invoice/${row.original.client_id}`, {
                  state: {
                    id: row.original.id,
                    invoiceNo: invoiceNo,
                    adHocMailInvoice: adHocMailInvoice,
                  },
                });
              }}
            >
              <PlusIconSVG />
            </div>
          )}
          <Tooltip
            id="preview-invoice-tooltip"
            style={{
              background: "#000",
              color: "#fff",
            }}
            opacity={0.9}
          />
          {row.original.invoice_url ? (
            <div
              data-tooltip-id="preview-invoice-tooltip"
              data-tooltip-content="Preview Invoice"
              data-tooltip-place="top"
            >
              <div
                onClick={() => {
                  const invoiceUrl = `${mainURL}/${row.original.invoice_url}`;
                  const rowData = row.original;
                  const assignId = row.original.id;
                  const clientEmail = row.original.client_email;
                  navigate("/advance-billing-invoice", {
                    state: {
                      adHocMail: "adHocMail",
                      invoice_id: row.original.id,
                      invoice_url: invoiceUrl,
                      row: rowData,
                      assignId: assignId || null,
                      clientEmail: clientEmail,
                      breadcrumbs: [
                        { pageName: "Ad Hoc Billing", pageURL: "/add-hoc" },
                        { pageName: "Invoice", pageURL: "" },
                      ],
                    },
                  });
                }}
              >
                <InvoiceIcon />
              </div>
            </div>
          ) : null}
        </div>
      ),
    },
  ];

  const columnHeaders = ["Sr no.", "invoice No", "Bpo No", "Client Name"];
  const [searchInput, setSearchInput] = useState("");

  const columns = useMemo(() => tableColumns, []);
  // const data = useMemo(() => adHocClientsList, [adHocClientsList]);
  const data = useMemo(() => {
    const filteredData = adHocClientsList.filter((row) => {
      const additionalData = JSON.parse(row.additional_data);
      const bpoNo = additionalData.bpo_no?.toString().toLowerCase();
      const clientName = row.client_name?.toLowerCase();
      return (
        clientName.includes(searchInput.toLowerCase()) ||
        (bpoNo && bpoNo.includes(searchInput.toLowerCase()))
      );
    });

    return filteredData.sort((a, b) => {
      if (a.invoice_url === null && b.invoice_url !== null) {
        return -1;
      } else if (a.invoice_url !== null && b.invoice_url === null) {
        return 1;
      } else {
        return new Date(b.created_on) - new Date(a.created_on);
      }
    });
  }, [adHocClientsList, searchInput]);


  const tableInstance = useTable(
    {
      columns,
      data,
     
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );


  const { state, setGlobalFilter } = tableInstance;
  const { globalFilter } = state;

  // constructing headers for CSV Link
  const headers = {
    headings: [
      { label: "invoice no", key: "invoice_no" },
      { label: "Created Date", key: "created_on" },
      { label: "Client Name", key: "client_name" },
    ],
    fileName: "Ad Hoc Client List",
  };
  useEffect(() => {
    setOptions((prev) => ({
      ...prev,
      clientOptions: initialState?.clientsList?.map(
        ({ client_name, additional_data, client_id }) => {
          const companyName = additional_data?.company_name || "";
          const bpoNo = additional_data?.bpo_no?.toString() || "";
          const label = `${client_name} (${companyName}${
            bpoNo ? ` - ${bpoNo}` : ""
          })`;
          return { label, value: client_id };
        }
      ),
    }));
  }, [initialState.clientsList]);

  const addNewClient = async () => {
    const body = {
      client_id: client?.clientId?.value,
      current_user: localStorage.getItem("userId") ?? null,
    };

    setIsUserValid(true);

    try {
      const url = `${mainURL}add/invoice/ad-hoc-billing`;
      const result = await axios.post(url, body, { headers: headerOptions() });

      if (result.status === 201) {
        ReactHotToast(result?.data?.message, "success");
        setClient({ clientId: null });
        setIsUpdated((prev) => !prev);
      }
    } catch (e) {
      handleAPIError(e, logout);
    } finally {
      setIsUserValid(false);
    }
  };

  const handleAddClient = (e) => {
    e.preventDefault();
    if (client) {
      addNewClient();
    } else {
      ReactHotToast("Please select client!", "error");
    }
  };

  const handleDropDown = (dropDown, option) => {
    if (dropDown === "clientId") {
      setClient((prev) => ({ ...prev, clientId: option }));
    }
  };

  return (
    <div className={`main-content ${sidebarClose ? "sidebarClose" : ""}`}>
      <div className="mr-40 ml-30 mb-15">
        <Breadcrumbs crumbs={breadCrumbs} />
      </div>

      <section className="main-content_header add-border-bottom custom-border-bottom">
        <div className="d-flex justify-content-center align-items-center page-heading">
          <img src={adHoc} alt="members" />
          <p className="m-0 fs-4">Ad Hoc Billing</p>
        </div>
        <div className="d-flex justify-content-center align-items-center gap-3">
          <div className="relative-wrapper">
            <img className="search-icon" src={searchIcon} alt="search-icon" />
            <input
              className="input-field"
              type="text"
              placeholder="Search"
              value={searchInput}
              onChange={(e) => setSearchInput(e.target.value)}
            />
          </div>
        </div>
      </section>

      <section className="main-content_form-section gap-3 d-flex flex-column justify-content-start align-items-center width-65 m-auto">
        <form
          onSubmit={handleAddClient}
          className="w-100 mt-5 d-flex justify-content-between align-items-end gap-3"
        >
          <div className="flex-1 form-group">
            <label htmlFor="clientId">Select Client:</label>
            <Select
              name="clientId"
              closeMenuOnSelect={true}
              options={options.clientOptions}
              onChange={(option) => handleDropDown("clientId", option)}
              value={client.clientId}
              className="react-select-custom-styling__container"
              classNamePrefix="react-select-custom-styling"
            />
          </div>
          <button type="submit" className=" custom-btn">
            {isUserValid ? <SpinningLoader /> : "Add Client"}
          </button>
        </form>

        <div className="d-flex flex-column gap-3 w-100">
          {isLoading ? (
            <ReactTableSkeleton columnHeaders={columnHeaders} />
          ) : adHocClientsList.length > 0 ? (
            <AdHocTable
              tableInstance={tableInstance}
              headers={headers}
              adHocClientsList={adHocClientsList}
            />
          ) : (
            <div className="mr-40 mt-3 mb-15">
              <h5>No Data Found!</h5>
            </div>
          )}
        </div>
      </section>
    </div>
  );
};

export default AdHocContent;
