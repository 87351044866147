import React from "react";

import ReactTable from "../../../templates/ReactTable";
import ReactTableFooter from "../../../templates/ReactTableFooter";

const AdvanceBillingTable = ({ tableInstance, headers, advancBillingJobsData }) => {
  return (
    <section className="ml-30 mr-40">
      <ReactTable tableInstance={tableInstance} />
      <ReactTableFooter
        headers={headers}
        data={advancBillingJobsData}
        tableInstance={tableInstance}
      />
    </section>
  );
};

export default AdvanceBillingTable;
