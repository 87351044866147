import React, { useContext, useMemo, useState } from "react";
import {
  useTable,
  useGlobalFilter,
  useSortBy,
  usePagination,
} from "react-table";
import Badge from "react-bootstrap/Badge";
import Stack from "react-bootstrap/Stack";
import { fileIcon } from "../../../../../utils/ImportingImages/ImportingImages";
import { ContextSidebarToggler } from "../../../../../Context/SidebarToggler/SidebarToggler";
import Breadcrumbs from "../../../../../templates/Breadcrumbs";
import ReactTableSkeleton from "../../../../../templates/ReactTableSkeleton";
import JobsTable from "./JobsTable";
import PageHeader from "../../../../../templates/PageHeader";
import { formatDate } from "../../../../../utils/utilities/utilityFunctions";

const JobsContent = ({
  jobsData,
  advanceBillingJob,
  setIsUpdated,
  isLoading,
}) => {
  const { sidebarClose } = useContext(ContextSidebarToggler);
  const [activeTab, setActiveTab] = useState("myJobs");
  const breadCrumbs = [
    { pageName: "Home", pageURL: "/dashboard" },
    { pageName: "Jobs", pageURL: "/jobs" },
  ];

  // Move data definition here
  const data = useMemo(
    () => (activeTab === "myJobs" ? jobsData : advanceBillingJob),
    [jobsData, advanceBillingJob, activeTab]
  );

  const tableColumns = useMemo(() => {
    const baseColumns = [
      {
        Header: "Sr no.",
        accessor: "sr no.",
        Cell: ({ row }) => row.index + 1,
      },
      {
        Header: "BPO No",
        accessor: (row) => {
          try {
            const clientData = JSON.parse(row.client_data);
            return clientData.bpo_no || "N/A";
          } catch (error) {
            return "N/A";
          }
        },
      },
      {
        Header: "Job Name",
        accessor: "job_name",
      },
      {
        Header: "Assigned on",
        accessor: "assigned_on",
        Cell: ({ row }) => formatDate(row.original.assigned_on),
      },
    ];

    // Conditionally add the Due Date column
    if (!data.some((row) => row.due_on)) {
      baseColumns.push({
        Header: "Allocated Hours",
        accessor: "allocated_hours",
        Cell: ({ row }) => {
          const time = row.original.allocated_hours;
          return typeof time === "string" ? time.slice(0, -3) : "";
        },
      });
    } else {
      baseColumns.push({
        Header: "Due Date",
        accessor: "due_on",
        Cell: ({ row }) => formatDate(row.original.due_on),
      });
    }

    baseColumns.push({
      Header: "Status",
      accessor: "job_status",
      Cell: ({ row }) => (
        <div className="d-flex justify-content-center align-items-center">
          <Stack direction="horizontal">
            {row.original.job_status === "Completed" ? (
              <Badge bg="success">Completed</Badge>
            ) : row.original.job_status === "On Hold" ? (
              <Badge bg="danger">On Hold</Badge>
            ) : (
              <Badge bg="warning" text="dark">
                In Progress
              </Badge>
            )}
          </Stack>
        </div>
      ),
    });

    baseColumns.push({
      Header: "Description",
      accessor: "job_description",
    });

    return baseColumns;
  }, [data]);

  const columns = useMemo(() => tableColumns, [tableColumns]);

  const tableInstance = useTable(
    { columns, data },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const headers = {
    headings: [
      { label: "Job Name", key: "job_name" },
      { label: "Assigned on", key: "assigned_on" },
      { label: "Due Date", key: "due_on" },
      { label: "Status", key: "job_status" },
      { label: "Description", key: "job_description" },
    ],
    fileName: "Jobs",
  };

  return (
    <div className={`main-content ${sidebarClose ? "sidebarClose" : ""}`}>
      <div className="mr-40 ml-30 mb-15">
        <Breadcrumbs crumbs={breadCrumbs} />
      </div>

      <div className="mb-5 relative-wrapper zIndex-2">
        <PageHeader
          tableInstance={tableInstance}
          icon={fileIcon}
          headerTitle={"Jobs"}
        />
      </div>

      {/* <div className="mr-40 ml-30 mt-5 mb-15 w-75 d-flex justify-content-start align-items-center gap-1">
        <button
          className={`btn ${activeTab === "myJobs" ? "btn-light" : "btn-secondary"} me-2`}
          style={{
            backgroundColor: activeTab === "myJobs" ? "#00263d" : "",
            color: activeTab === "myJobs" ? "white" : "",
            fontWeight: "500",
          }}
          onClick={() => setActiveTab("myJobs")}
        >
          My Jobs
        </button>
        <button
          className={`btn ${activeTab === "advanceBilling" ? "btn-light" : "btn-secondary"}`}
          style={{
            backgroundColor: activeTab === "advanceBilling" ? "#00263d" : "",
            color: activeTab === "advanceBilling" ? "white" : "",
            fontWeight: "500",
          }}
          onClick={() => setActiveTab("advanceBilling")}
        >
          Advance Billing Jobs
        </button>
      </div> */}

      {isLoading ? (
        <ReactTableSkeleton
          columnHeaders={headers.headings.map((h) => h.label)}
        />
      ) : data?.length > 0 ? (
        <div className="jobs-table">
          <JobsTable
            tableInstance={tableInstance}
            headers={headers}
            jobsData={data}
          />
        </div>
      ) : (
        <div className="mr-40 ml-30 mt-4 mb-15">
          <h5>No Data found!</h5>
        </div>
      )}
    </div>
  );
};

export default JobsContent;
