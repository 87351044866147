

import React, { useContext, useMemo, useEffect, useState } from "react";
import { ContextSidebarToggler } from "../../../../Context/SidebarToggler/SidebarToggler";
import Breadcrumbs from "../../../../templates/Breadcrumbs";
import {
  DownSVG,
  reportsIcon,
  teamsIcon1,
} from "../../../../utils/ImportingImages/ImportingImages";
import styles from "./TeamsContent.module.css";
import { ContextAPI } from "../../../../Context/ApiContext/ApiContext";

const TeamMemberList = ({ setIsUpdated, isLoading, content }) => {
  const { sidebarClose } = useContext(ContextSidebarToggler);
  const { getTeamDetailsByMemberId, initialState } = useContext(ContextAPI);
  const [teamData, setTeamData] = useState(null);

  const breadCrumbs = [
    { pageName: "Home", pageURL: "/dashboard" },
    { pageName: "Teams", pageURL: "/teams" },
  ];

  useEffect(() => {
    getTeamDetailsByMemberId();
  }, []);

  useEffect(() => {
    setTeamData(initialState.myTeams);
  }, [initialState.myTeams]);



  const parseMembers = (memberNamesString) => {
    const members = memberNamesString.split(",").map((member) => member.trim());
    const parsedMembers = members.map((member) => {
      const [name, role] = member.split("(");
      return {
        name: name.trim(),
        role: role ? role.replace(")", "").trim() : "Member",
      };
    });

    // Sort members with "Leader" and "Sub-Leader" on top
    return parsedMembers.sort((a, b) => {
      const roleOrder = {
        "Leader": 1,
        "Sub Leader": 2,
        "Member": 3,
      };
      return (roleOrder[a.role] || 4) - (roleOrder[b.role] || 4);
    });
  };

  const membersData = teamData?.member_names ? parseMembers(teamData.member_names) : [];

  return (
    <div className={`main-content ${sidebarClose ? "sidebarClose" : ""}`}>
      <div className="mr-40 ml-30 mb-15">
        <Breadcrumbs crumbs={breadCrumbs} />
      </div>

      {/* Top header section */}
      <div className="mb-5">
        <section className="main-content_header">
          <div className="d-flex justify-content-center align-items-center page-heading">
            <img src={teamsIcon1} alt="Teams" />
            <p className="m-0 fs-4">Team Member</p>
          </div>
        </section>
      </div>

      {/* Team Details */}
      <div className="mr-40 ml-30 mb-15">
        {teamData?.team_name ? (
          <div className="my-teams-wrapper w-75">
            <p className={`m-0 ${styles.heading}`}>
              {teamData.team_name}
            </p>
            <p className={`m-0 mt-4 ${styles.title}`}>Members list</p>
            <p className={`m-0 mt-2 ${styles.membersCount}`}>
              {teamData?.member_count} members
            </p>

            {/* Members Table */}
            <table className="table table-bordered table-hover mt-4">
              <thead>
                <tr>
                <th style={{width:"57px"}}>Sr no</th>
                  <th>Member Name</th>
                  <th>Role</th>
                </tr>
              </thead>
              <tbody>
                {membersData.map((member, index) => (
                  <tr key={index}>
                    <td className={`${styles.text}`}>{index+1}</td>
                    <td className={`${styles.text}`}>{member.name}</td>
                    <td className={`${styles.text}`}>{member.role}</td>
                  </tr>
                ))}
              </tbody>
            </table>

          </div>
        ) : (
          <div className="mr-40 ml-30 mb-15">
            <h5>You are not a part of any Team yet!</h5>
          </div>
        )}
      </div>
    </div>
  );
};

export default TeamMemberList;


